import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

const Experience = () => {
  return (
    <div className='experience'>
      <VerticalTimeline lineColor='#21325e'>
      <VerticalTimelineElement 
          className='vertical-timeline-element--work'
          date='2021 - 2021'
          iconStyle={{background: "#e9d35b", color: "#fff"}}
          icon={<WorkIcon />} >
            <h3 className='vertical-timeline-element-title'>
              Software Developer Intern - Bank of Ceylon
            </h3>
            <h4 className='vertical-timeline-element-subtitle'>
              Colombo, Sri Lanka
            </h4>
            <p>Design, Develop and Test web development project using HTML, CSS, Java, and MySQL</p>
          </VerticalTimelineElement>
      
        <VerticalTimelineElement 
          className='vertical-timeline-element--education'
          date='2016 - 2021'
          iconStyle={{background: "#21325e", color: "#fff"}}
          icon={<SchoolIcon />} >
            <h3 className='vertical-timeline-element-title'>
              University Of Peradeniya, Sri Lanka
            </h3>
            <p>Bachelor of Science(Hons) Degree</p>
            <p>GPA: 3.24</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement 
          className='vertical-timeline-element--education'
          date='2007 - 2015'
          iconStyle={{background: "#21325e", color: "#fff"}}
          icon={<SchoolIcon />} >
            <h3 className='vertical-timeline-element-title'>
              Galahitiyawa Central College, Ganemulla, Sri Lanka
            </h3>
            <p>High School</p>
            <p>Advanced Level Examination 2015 (4 "A"s: Combined Mathematics, Physics, Chemistry, and General English)</p>
            <p>Ordinary level Examination 2012 (8 "A"s and 1 "B")</p>
          </VerticalTimelineElement>
          
      </VerticalTimeline>
    </div>
  )
}

export default Experience
