import project1 from "../assets/skRestaurant_front.JPG";
import project2 from "../assets/skRestaurant_back.JPG";
import project3 from "../assets/LMS_BOC.PNG";
import project4 from "../assets/fyp.JPG";

export const ProjectList = [
    {
        name: "Spicy Kitchen Restaurant Website",
        image: project1,
        skills: "React, HTML, CSS, Bootstrap and Firebase",
        description: "A responsive website for the customers to find information of the restaurant. Customers can send messages and, they are stored in the database. Customers can view food menus which are stored in the database and, loaded to the website.",
        link: "https://skrestaurant.madushiranasooriyaportfolio.com/"
    },
    {
        name: "Backend Interface for Spicy Kitchen Restaurant",
        image: project2,
        skills: "React, HTML, CSS, Bootstrap and Firebase",
        description: "A web interface for the admin and, staff of the restaurant to add details to the website. There are admin users and normal users. They can see the messages from customers and send replies to them. They can add or delete food menus which are displayed in the main website. Only admins have the privilege to add or remove staff and, promote staff member to an admin or demote admin to a normal user.",
        link: "https://skrestaurantback.madushiranasooriyaportfolio.com/"
    },
    {
        name: "Letter Management System",
        image: project3,
        skills: "HTML, CSS, Java, and MySQL",
        description: "A website to track letters. Each letter has an unique barcode and, when sending or receiving a letter that barcode should be entered to the system. Barcode is scanned using a mobile app. Letter details are displayed in the website and, users can track the letters. Only admin can add or remove users."
    },
    {
        name: "Motor Traffic Control System",
        image: project4,
        description: "Implementing a P2P connection on top of the selected hardware module, Clustering peers, Assigning super nodes, Enabling routine among nodes. "
    }
]